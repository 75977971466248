module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Cinzel Decorative:700,900","PT Serif: 400, 400i, 700","Oswald: 500"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pulling Teeth - Baltimore Hardcore","short_name":"Pulling Teeth","start_url":"/","background_color":"#111111","theme_color":"HEX VALUE","display":"minimal-ui","icon":"src/images/PTlogo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"42350b8360017dcd7f3966a7420aaeb8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-accessibilityjs/gatsby-browser.js'),
      options: {"plugins":[],"injectStyles":false,"errorClassName":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
